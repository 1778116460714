// two-field-search
import React, { useEffect, useState } from "react";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import "./bookAppointmentForm.css";
import { createPdfBlobUrl } from "../../../utils/document.utils";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { paymentIntentCallProxy } from "../../../utils/stripe.service";
import { orderDataPayload } from "../../../utils/payloads.utils";
import { postOrder } from "../../../utils/backoffice.service";
import {
  servicesInteractiveForm,
  servicesSuitabilityForm,
} from "../../../data/data";
import { Link } from "react-router-dom";
import { emailValidator, phoneValidator } from "../../../utils/validators";
import { getCustomer } from "../../../utils/usermgmt.service";
import { getOverBookedSlots } from "../../../utils/sales.service";
import { getBlockedSlots } from "../../../utils/pctmgmt.services";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const BookAppointmentForm = (data) => {
  // Variables
  const sessionCustomerId = sessionStorage.getItem("customerId");
  const [practitioner, setPractitioner] = useState(null);
  const [service, setService] = useState(null);
  const [businessDays, setBusinessDays] = useState(null);
  const [businessStartTime, setBusinessStartTime] = useState(null);
  const [businessCloseTime, setBusinessEndTime] = useState(null);
  const [overbookedSlots, setOverbookedSlots] = useState([]);
  const [practitionerBlockedSlots, setPractitionerBlockedSlots] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [showConsentLink, setShowConsentLink] = useState(false);
  const [consent, setConsent] = useState(false);
  const [ageConsent, setAgeConsent] = useState(false);
  const [informationConsent, setInformationConsent] = useState(false);
  const [showInformationLink, setShowInformationLink] = useState(false);
  const [showInteractiveForm, setShowInteractiveForm] = useState(false);
  const [interactiveForm, setInteractiveForm] = useState(null);
  const [interactiveFormData, setInteractiveFormData] = useState(null);
  const [informationLink, setInformationLink] = useState(null);
  const [consentForm, setConsentForm] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(null);
  const [startTimeFormatted, setStartTimeFormatted] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [serviceRequestNotes, setServiceRequestNotes] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [stripeApiUp, setStripeApiUp] = useState(true);
  const [loadStrip, setLoadStripe] = useState(false);
  const [stripeIntentData, setStripIntentData] = useState(null);
  const [serviceMethod, setServiceMethod] = useState(null);
  const [serviceMethods, setServiceMethods] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [validForm, setValidForm] = useState(false);
  const [showSuitabilitiyForm, setShowSuitabilityForm] = useState(false);
  const [showUnSuitabilitiyForm, setShowUnSuitabilityForm] = useState(false);
  const [suitabilityForm, setSuitabilityForm] = useState(null);
  const [unSuitabilityForm, setUnSuitabilityForm] = useState(null);
  const [suitabilityFormData, setSuitabilityFormData] = useState(null);
  const [unSuitabilityFormData, setUnSuitabilityFormData] = useState(null);
  const [suitabilityError, setSuitabilityError] = useState(false);
  const [firstNameValidation, setFirstNameValidation] = useState({
    valid: false,
    message: "",
  });
  const [lastNameValidation, setLastNameValidation] = useState({
    valid: false,
    message: "",
  });
  const [emailValidation, setEmailValidation] = useState({
    valid: false,
    message: "",
  });
  const [phoneNumberValidation, setPhoneNumberValidation] = useState({
    valid: false,
    message: "",
  });
  const [methodValidation, setMethodValidation] = useState({
    valid: false,
    message: "",
  });

  // Customer Id
  useEffect(() => {
    setCustomerId(sessionCustomerId);
  }, [sessionCustomerId]);

  // Customer Data
  useEffect(() => {
    if (customerId) {
      getCustomer(customerId).then((response) => {
        if (response.data) {
          setCustomer(response.data);
        }
      });
    }
  }, [customerId]);

  // Pre Populate
  useEffect(() => {
    if (customer) {
      if (customer?.user?.first_nm) {
        setFirstName(customer?.user?.first_nm);
        setFirstNameValidation({ valid: true, message: "" });
      }

      if (customer?.user?.last_nm) {
        setLastName(customer?.user?.last_nm);
        setLastNameValidation({ valid: true, message: "" });
      }

      if (customer?.customer_email) {
        setEmailAddress(customer?.customer_email);
        setEmailValidation({
          valid: emailValidator(customer?.customer_email),
          message: "Please Enter Valid Email Address",
        });
      }

      if (customer?.customer_email) {
        setPhoneNumber(customer?.customer_phone);
        setPhoneNumberValidation({
          valid: phoneValidator(customer.customer_phone),
          message: "Please Enter Valid Phone Number",
        });
      }
    }
  }, [customer]);

  // Form Validator
  useEffect(() => {
    setValidForm(
      practitioner &&
        service &&
        firstNameValidation.valid &&
        lastNameValidation.valid &&
        emailValidation.valid &&
        phoneNumberValidation.valid &&
        methodValidation.valid &&
        startDate &&
        startTime &&
        ageConsent &&
        !suitabilityError,
    );
  }, [
    firstNameValidation,
    lastNameValidation,
    emailValidation,
    phoneNumberValidation,
    methodValidation,
    startDate,
    startTime,
    suitabilityError,
    ageConsent,
    practitioner,
    service,
  ]);

  // NHS Claims Amount
  useEffect(() => {
    if (
      data.serviceSearchData &&
      data.serviceSearchData.service &&
      data.serviceSearchData.isNhs
    ) {
      data.serviceSearchData.service.srvc_price_amt = 0;
    }
  });

  // Consent Form
  useEffect(() => {
    if (
      data &&
      data.serviceSearchData &&
      data.serviceSearchData.consentTemplate
    ) {
      setShowConsentLink(true);
      setConsentForm(data?.serviceSearchData?.consentTemplate[0]);
    } else {
      setShowConsentLink(false);
    }
  }, [data]);

  // Information Link
  useEffect(() => {
    if (data && data.serviceSearchData) {
      let consulation_types = data.serviceSearchData.service.consultation_type;
      let mets = Object.keys(consulation_types);
      mets = mets.filter((m) => consulation_types[m]);
      setServiceMethods(mets);
    }
  }, [data]);

  // Practitioner
  useEffect(() => {
    if (data && data.serviceSearchData) {
      setService(data.serviceSearchData.service);
      setPractitioner(data.serviceSearchData.practitionerData);
      setBusinessDays(
        data.serviceSearchData.practitionerData.business_days_json,
      );
      setBusinessStartTime(
        data.serviceSearchData.practitionerData.business_hours_start_tm_json,
      );
      setBusinessEndTime(
        data.serviceSearchData.practitionerData.business_hours_close_tm_json,
      );
    } else {
      setService(null);
      setPractitioner(null);
      setBusinessDays(null);
      setBusinessStartTime(null);
      setBusinessEndTime(null);
      setAgeConsent(false);
      setConsent(false);
      setInformationConsent(false);
      setServiceMethod(null);
      setServiceRequestNotes(null);
      setStartDate(new Date());
      setStartTime(null);
    }
  }, [data]);

  // Practitioner Business Hours
  useEffect(() => {
    if (practitioner && startDate) {
      // Over Booked
      getOverBookedSlots({
        p_nbr: practitioner.p_nbr,
        date: moment(startDate).format("YYYY-MM-DD"),
      }).then((res) => {
        if (res && res.length > 0) {
          let times = res.map((item) => new Date(item));
          setOverbookedSlots(times);
        } else {
          setOverbookedSlots([]);
        }
      });

      // Blocked
      getBlockedSlots({
        p_nbr: practitioner.p_nbr,
        date: moment(startDate).format("YYYY-MM-DD"),
      }).then((res) => {
        console.log(res);
        if (res.data && res.data.length > 0) {
          let times = res.data.map((item) => new Date(item));
          setPractitionerBlockedSlots(times);
        } else {
          setPractitionerBlockedSlots([]);
        }
      });
    }
  }, [practitioner, startDate]);

  // Interactive Form
  useEffect(() => {
    if (
      servicesInteractiveForm &&
      data &&
      data.serviceSearchData &&
      servicesInteractiveForm[data.serviceSearchData.serviceId]
    ) {
      if (
        servicesInteractiveForm[data.serviceSearchData.serviceId].nhsConsentLink
      ) {
        // Show Link and Form Booleans
        setShowInformationLink(true);
        setShowInteractiveForm(true);

        // Link and Form Values
        setInformationLink(
          servicesInteractiveForm[data.serviceSearchData.serviceId]
            .nhsConsentLink,
        );
        setInteractiveForm(
          servicesInteractiveForm[data.serviceSearchData.serviceId]
            .questionnaire,
        );

        // Initial Form Data
        setInteractiveFormData(
          servicesInteractiveForm[
            data.serviceSearchData.serviceId
          ].questionnaire.map((d, i) => {
            return false;
          }),
        );
      }
    } else {
      setInteractiveForm(null);
      setInteractiveFormData(null);
      setInformationLink(null);
      setShowInformationLink(false);
      setShowInteractiveForm(false);
    }
  }, [data, interactiveForm]);

  // Suitability Form
  useEffect(() => {
    if (
      servicesInteractiveForm &&
      data &&
      data.serviceSearchData &&
      servicesSuitabilityForm[data.serviceSearchData.serviceId]
    ) {
      let suitData = servicesSuitabilityForm[data.serviceSearchData.serviceId];
      // Suitability
      if (suitData.suitable && suitData.suitable.length > 0) {
        setSuitabilityError(true);
        setShowSuitabilityForm(true);
        setSuitabilityForm(suitData.suitable);
        setSuitabilityFormData(
          suitData.suitable.map((d, i) => {
            return false;
          }),
        );
      } else {
        setShowSuitabilityForm(false);
        setSuitabilityForm(null);
        setSuitabilityFormData(null);
        setSuitabilityError(false);
      }
      // Unsuitability
      if (suitData.unsuitable && suitData.unsuitable.length > 0) {
        setShowUnSuitabilityForm(true);
        setUnSuitabilityForm(suitData.unsuitable);
        setUnSuitabilityFormData(
          suitData.unsuitable.map((d, i) => {
            return false;
          }),
        );
      } else {
        setShowUnSuitabilityForm(false);
        setUnSuitabilityForm(null);
        setUnSuitabilityFormData(null);
        setSuitabilityError(false);
      }
    }
  }, [data, suitabilityForm, unSuitabilityForm]);

  const interactiveFormHandler = (id, value) => {
    if (interactiveFormData.length >= id) {
      interactiveFormData[id] = value;
    }
  };

  const suitabilityFormHandler = (id, value) => {
    if (suitabilityFormData.length >= id) {
      let formData = [...suitabilityFormData];
      formData[id] = value;
      setSuitabilityFormData(formData);
      setSuitabilityError(!formData.includes(true));
    }
  };

  const unsuitabilityFormHandler = (id, value) => {
    if (unSuitabilityFormData.length >= id) {
      let formData = [...unSuitabilityFormData];
      formData[id] = value;
      setUnSuitabilityFormData(formData);
      setSuitabilityError(formData.includes(true));
    }
  };

  // Stripe Options
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
    loader: "always",
  };

  // Function to disable non-business days
  const isBusinessDay = (date) => {
    const dayName = moment(date).format("dddd"); // Get the day name (Monday, Tuesday, etc.)
    return businessDays ? businessDays[dayName] : true; // Return true/false based on business days
  };

  // Get start and end hours for a specific day
  const getBusinessHoursForDay = (dayName) => {
    return {
      start: businessStartTime[dayName],
      close: businessCloseTime[dayName],
    };
  };

  const firstNameChange = (e) => {
    setFirstName(e.target.value);
    if (!e.target.value) {
      setFirstNameValidation({
        valid: false,
        message: "First Name Is Required",
      });
    } else {
      setFirstNameValidation({ valid: true, message: "" });
    }
  };

  const lastNameChange = (e) => {
    setLastName(e.target.value);
    if (!e.target.value) {
      setLastNameValidation({
        valid: false,
        message: "Last Name Is Required",
      });
    } else {
      setLastNameValidation({ valid: true, message: "" });
    }
  };

  const phoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    if (phoneValidator(e.target.value)) {
      setPhoneNumberValidation({ valid: true, message: "" });
    } else {
      setPhoneNumberValidation({
        valid: false,
        message: "Please Enter Valid Phone Number of format 01111111111",
      });
    }
  };

  const emailAddressChange = (e) => {
    setEmailAddress(e.target.value);
    // Validate Email
    if (emailValidator(e.target.value)) {
      setEmailValidation({ valid: true, message: "" });
    } else {
      setEmailValidation({
        valid: false,
        message: "Please Enter Valid Email Address",
      });
    }
  };

  const serviceRequestNoteChange = (e) => {
    setServiceRequestNotes(e.target.value);
  };

  const handleTimeChange = (time) => {
    let fmttime = moment(time).utc().format();
    setStartTime(time);
    setStartTimeFormatted(fmttime);
  };

  const handleDateChange = (date) => {
    if (date) {
      // Get Business Hour Start:
      let startHour =
        businessStartTime && businessCloseTime
          ? setHours(
              setMinutes(new Date(), 0),
              parseInt(
                getBusinessHoursForDay(
                  moment(startDate).format("dddd"),
                ).start.split(":")[0],
              ),
            )
          : setHours(startDate, 10);
      startHour = startHour.getHours() || 0;
      console.log(startHour);
      date = moment(date).hour(startHour).minute(0).toDate();
      setStartDate(date);
      handleTimeChange(date);
    }
  };

  // Consent Template Down
  const consentDownload = () => {
    createPdfBlobUrl(consentForm);
  };

  // Service Method
  const selectServiceMethod = (e) => {
    // Set Value
    setServiceMethod(e.target.value);
    if (!e.target.value) {
      setMethodValidation({
        valid: false,
        message: "Method Is Required",
      });
    } else {
      setMethodValidation({ valid: true, message: "" });
    }
  };

  // Handle Submit
  const handleSubmit = () => {
    // Error
    setSuitabilityError(false);
    // Interaction Form Data
    let iFormData = [];
    if (interactiveFormData) {
      interactiveForm.map((d, i) => {
        let data = { question: d.question, value: interactiveFormData[i] };
        iFormData.push(data);
      });
    }
    if (suitabilityFormData) {
      suitabilityForm.map((d, i) => {
        let data = { question: d.question, value: suitabilityFormData[i] };
        iFormData.push(data);
      });
    }
    if (unSuitabilityFormData) {
      unSuitabilityForm.map((d, i) => {
        let data = { question: d.question, value: unSuitabilityFormData[i] };
        iFormData.push(data);
      });
    }
    let payload = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      emailAddress: emailAddress,
      bookingDate: startDate,
      startTime: startTimeFormatted,
      endTime: moment(startTimeFormatted)
        .add(data.serviceSearchData.service.srvc_duration_time, "minutes")
        .utc()
        .format(),
      serviceMethod: serviceMethod,
      serviceRequestNotes: serviceRequestNotes,
      interactiveFormData: iFormData,
      ...data.serviceSearchData,
      customer: customer,
    };

    // 1. Save Order Data
    payload = orderDataPayload(payload);
    postOrder(payload).then((r) => {
      setOrderData({ ...payload, ...r.data });
      // 2. Stripe
      if (!service.nhs_in && service.srvc_price_amt > 0) {
        callStripIntent({
          amount: data.serviceSearchData.service.srvc_price_amt,
          orderId: r.data.id,
          emailAddress: r.data.emailId,
          stripeConnectAccountId: data.serviceSearchData
            .practitionerStripeAccount
            ? data.serviceSearchData.practitionerStripeAccount.account_id
            : null,
        });
      }
    });

    // 3. Save Payment Data
    data.handleOrderData(payload);
  };

  function callStripIntent(data) {
    let payload = {
      amount: data.amount * 100,
      currency: "gbp",
      "automatic_payment_methods[enabled]": true,
      orderId: data.orderId,
      receipt_email: data.emailAddress,
      stripeConnectAccountId: data.stripeConnectAccountId,
      type: "appointment",
    };
    paymentIntentCallProxy(payload)
      .then((r) => {
        setStripIntentData(r.data);
        setClientSecret(r.data.client_secret);
        setLoadStripe(true);
        setStripeApiUp(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 503) {
          setStripeApiUp(false);
        }
      });
  }

  return (
    <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
      <div
        className="card border-0 rounded shadow p-4 ms-xl-3"
        style={{ backgroundColor: "#ffffff" }}
      >
        <div className="custom-form" style={{ backgroundColor: "#ffffff" }}>
          <form>
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-3">
                  <label className="form-label">Practitioner</label>
                  <input
                    name="practitioner"
                    id="practitioner"
                    type="text"
                    className="form-control text-primary bg-muted"
                    placeholder="Practitioner :"
                    readOnly={true}
                    value={
                      practitioner ? practitioner.business_nm : "Practitioner :"
                    }
                  />
                </div>
              </div>
              {/*Hiding Cost for NHS Service*/}
              {data && data.serviceSearchData?.isNhs ? (
                <div className="col-lg-12">
                  <div className="mb-3">
                    <input
                      name="serviceName"
                      id="serviceName"
                      type="text"
                      className="form-control text-primary bg-muted"
                      placeholder="Service :"
                      readOnly={true}
                      value={service ? service.srvc_desc : "Service :"}
                    />
                  </div>
                </div>
              ) : (
                <div className="col-lg-6">
                  <div className="mb-3">
                    <input
                      name="serviceName"
                      id="serviceName"
                      type="text"
                      className="form-control text-primary bg-muted"
                      placeholder="Service :"
                      readOnly={true}
                      value={service ? service.srvc_desc : "Service :"}
                    />
                  </div>
                </div>
              )}
              {data && data.serviceSearchData?.isNhs ? null : (
                <div className="col-lg-6">
                  <div className="mb-3">
                    <input
                      name="cost"
                      id="cost"
                      type="text"
                      className="form-control text-primary bg-muted"
                      placeholder="Service Cost: £"
                      readOnly={true}
                      value={
                        service
                          ? `Service Cost: £ ${service.srvc_price_amt}`
                          : null
                      }
                    />
                  </div>
                </div>
              )}

              <div className="col-lg-6">
                <div className="mb-3">
                  <input
                    name="firstName"
                    id="firstName"
                    type="text"
                    className="form-control"
                    required={true}
                    placeholder="Patient First Name :*"
                    onChange={firstNameChange}
                    value={firstName}
                    onKeyDown={(key) => {
                      if (key.key === "Enter") {
                        key.preventDefault();
                      }
                    }}
                  />
                  {!firstNameValidation.valid ? (
                    <span
                      className="text-danger"
                      style={{ fontSize: "smaller" }}
                    >
                      {" "}
                      {firstNameValidation.message}{" "}
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="mb-3">
                  <input
                    name="lastName"
                    id="lastName"
                    type="text"
                    className="form-control"
                    required={true}
                    placeholder="Patient Last Name :*"
                    onChange={lastNameChange}
                    value={lastName}
                    onKeyDown={(key) => {
                      if (key.key === "Enter") {
                        key.preventDefault();
                      }
                    }}
                  />
                  {!lastNameValidation.valid ? (
                    <span
                      className="text-danger"
                      style={{ fontSize: "smaller" }}
                    >
                      {" "}
                      {lastNameValidation.message}{" "}
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="mb-3">
                  <input
                    name="email"
                    id="email"
                    type="email"
                    className="form-control"
                    required={true}
                    placeholder="Your email :*"
                    onChange={emailAddressChange}
                    value={emailAddress}
                    onKeyDown={(key) => {
                      if (key.key === "Enter") {
                        key.preventDefault();
                      }
                    }}
                  />
                  {!emailValidation.valid ? (
                    <span
                      className="text-danger"
                      style={{ fontSize: "smaller" }}
                    >
                      {" "}
                      {emailValidation.message}{" "}
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="mb-3">
                  <input
                    name="phone"
                    id="phone"
                    type="tel"
                    className="form-control"
                    required={true}
                    placeholder="Your Phone* : 01223334444"
                    value={phoneNumber}
                    onChange={phoneNumberChange}
                    onKeyDown={(key) => {
                      if (key.key === "Enter") {
                        key.preventDefault();
                      }
                    }}
                  />
                  {!phoneNumberValidation.valid ? (
                    <span
                      className="text-danger"
                      style={{ fontSize: "smaller" }}
                    >
                      {" "}
                      {phoneNumberValidation.message}{" "}
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-control mb-3 border-1 border rounded">
                  <div className="text-start">
                    <DatePicker
                      className={"border-0"}
                      placeholderText="Choose Appointment Date*"
                      dateFormat="MMMM d, yyyy"
                      selected={startDate}
                      selectsStart
                      startDate={startDate}
                      minDate={new Date()}
                      filterDate={isBusinessDay}
                      onChange={handleDateChange}
                      placeholder={"Choose Appointment Date"}
                      onKeyDown={(key) => {
                        if (key.key === "Enter") {
                          key.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-control mb-3 border-1 border rounded">
                  <div className="text-start">
                    <DatePicker
                      className={"border-0"}
                      placeholderText="Choose Time*"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      selected={startTime}
                      selectsStart
                      startDate={startTime}
                      minDate={new Date()}
                      onChange={handleTimeChange}
                      excludeTimes={[
                        ...overbookedSlots,
                        ...practitionerBlockedSlots,
                      ]}
                      minTime={
                        businessStartTime && businessCloseTime
                          ? setHours(
                              setMinutes(new Date(), 0),
                              parseInt(
                                getBusinessHoursForDay(
                                  moment(startDate).format("dddd"),
                                ).start.split(":")[0],
                              ),
                            )
                          : setHours(startDate, 10)
                      }
                      maxTime={
                        businessStartTime && businessCloseTime
                          ? setHours(
                              setMinutes(new Date(), 0),
                              parseInt(
                                getBusinessHoursForDay(
                                  moment(startDate).format("dddd"),
                                ).close.split(":")[0],
                              ),
                            )
                          : setHours(startDate, 17)
                      }
                      onKeyDown={(key) => {
                        if (key.key === "Enter") {
                          key.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="mb-3">
                  <select
                    className="form-control"
                    value={serviceMethod}
                    onChange={selectServiceMethod}
                  >
                    <option defaultValue="default">
                      Select Service Method*
                    </option>
                    {serviceMethods
                      ? serviceMethods.map((met) => {
                          return (
                            <option key={met} value={met}>
                              {met}
                            </option>
                          );
                        })
                      : []}
                  </select>
                  {!methodValidation.valid ? (
                    <span
                      className="text-danger"
                      style={{ fontSize: "smaller" }}
                    >
                      {" "}
                      {methodValidation.message}{" "}
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="mb-3">
                  <textarea
                    name="comments"
                    id="comments"
                    rows="4"
                    className="form-control"
                    placeholder="Your Notes :"
                    onChange={serviceRequestNoteChange}
                    onKeyDown={(key) => {
                      if (key.key === "Enter") {
                        key.preventDefault();
                      }
                    }}
                  ></textarea>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="d-grid">
                  <div className="form-check">
                    <FormGroup>
                      <FormControlLabel
                        required
                        control={<Switch label="My Switch Button" />}
                        checked={ageConsent}
                        onChange={() =>
                          setAgeConsent((prevState) => !prevState)
                        }
                        // labelPlacement={"start"}
                        label={
                          <a>
                            I am 18 and above, booking my own appointment, and
                            accept ZipLaneRx’s{" "}
                            <Link
                              to="https://www.ziplanerx.com/aboutus/terms"
                              target="_blank"
                            >
                              Terms
                            </Link>
                          </a>
                        }
                        style={{ fontSize: "xx-small", textAlign: "start" }}
                      />
                    </FormGroup>
                    {showConsentLink ? (
                      <FormGroup>
                        <FormControlLabel
                          className="text-primary"
                          required
                          control={<Switch />}
                          onChange={() => setConsent((prevState) => !prevState)}
                          // labelPlacement={"start"}
                          label="Please check if service is suitable for you."
                          onClick={consentDownload}
                          style={{ fontSize: "xx-small", textAlign: "start" }}
                        />
                      </FormGroup>
                    ) : null}
                    {showInformationLink ? (
                      <FormGroup>
                        <FormControlLabel
                          className="text-primary"
                          required
                          control={<Switch />}
                          onChange={() =>
                            setInformationConsent((prevState) => !prevState)
                          }
                          label="Please check if service is suitable for you."
                          onClick={() => window.open(informationLink, "_blank")}
                          style={{ fontSize: "xx-small", textAlign: "start" }}
                        />
                      </FormGroup>
                    ) : null}
                    {showInteractiveForm ? (
                      <div>
                        <label
                          className="form-label"
                          style={{ fontWeight: "bold" }}
                        >
                          Please answer the following questions (Select all that
                          apply): <span className="text-danger">*</span>
                        </label>
                        {interactiveForm.map((d, i) => {
                          return (
                            <InteractiveForm
                              data={d.question}
                              id={i}
                              handler={interactiveFormHandler}
                            />
                          );
                        })}
                      </div>
                    ) : null}
                    {showUnSuitabilitiyForm || showSuitabilitiyForm ? (
                      <div>
                        <label
                          className="form-label"
                          style={{ fontWeight: "bold" }}
                        >
                          Please answer the following questions (Select all that
                          apply): <span className="text-danger">*</span>
                        </label>
                        {unSuitabilityForm &&
                          unSuitabilityForm.map((d, i) => {
                            return (
                              <InteractiveForm
                                data={d.question}
                                id={i}
                                handler={unsuitabilityFormHandler}
                              />
                            );
                          })}
                        {suitabilityForm &&
                          suitabilityForm.map((d, i) => {
                            return (
                              <InteractiveForm
                                data={d.question}
                                id={i}
                                handler={suitabilityFormHandler}
                              />
                            );
                          })}
                        {suitabilityError && !orderData && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "medium" }}
                          >
                            Based on your answers, you are ineligible for this
                            service!
                          </span>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="d-grid">
                  <button
                    type="button"
                    className="btn btn-primary fw-bolder"
                    disabled={
                      (showConsentLink && !consent) ||
                      (showInformationLink && !informationConsent) ||
                      (orderData &&
                        (data.serviceSearchData.isNhs || loadStrip)) ||
                      !validForm
                    }
                    onClick={handleSubmit}
                  >
                    {data.serviceSearchData?.isNhs
                      ? "Book Your Free Service"
                      : "Proceed To Payment"}
                  </button>
                </div>
                <div
                  className="col-lg-12 text-center mt-3"
                  style={{ fontSize: "medium" }}
                >
                  {orderData &&
                  (data.serviceSearchData.isNhs ||
                    data.serviceSearchData.service.srvc_price_amt === 0) ? (
                    <div className="d-grid">
                      <p>
                        {" "}
                        You are all set{" "}
                        <span className="text-primary">
                          {orderData.firstName}!
                        </span>
                      </p>
                      <p>
                        Your free {data.serviceSearchData.isNhs ? "NHS" : ""}{" "}
                        service at{" "}
                        <span className="text-primary">
                          {orderData.organisationName}
                        </span>
                      </p>
                      <p>
                        via <span className="text-primary">ZipLaneRx</span>
                      </p>
                      <div className="col-lg-12">
                        <div className="d-grid">
                          <Link
                            type="button"
                            className="btn btn-primary fw-bolder"
                            disabled={!orderData}
                            to={`/book/services/invoice?orderId=${orderData.id}`}
                          >
                            Continue to Your Invoice
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : orderData && !stripeApiUp ? (
                    <div className="d-grid">
                      <p>
                        {" "}
                        <span className="fw-bolder text-danger">
                          Stripe API is down{" "}
                        </span>
                        <span className="text-primary">
                          {orderData.firstName}!
                        </span>
                      </p>
                      <p>
                        <span className="text-danger">
                          Please try again later!
                        </span>
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {orderData &&
      loadStrip &&
      !data.serviceSearchData.isNhs &&
      data.serviceSearchData.service.srvc_price_amt > 0 ? (
        <div className="pt-0">
          <Elements stripe={stripePromise} options={options}>
            <PaymentForm
              data={orderData}
              handlePaymentData={data.handlePaymentData}
              clientSecret={clientSecret}
            />
          </Elements>
        </div>
      ) : null}
    </div>
  );
};

const PaymentForm = ({ data, handlePaymentData, clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  // Payment Submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${process.env.REACT_APP_frontoffice_base}/book/services/invoice?orderId=${data.id}`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      // Make Payment Call
    }
  };

  return (
    <form style={{ marginTop: 30 }} onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        className="btn btn-primary"
        type="submit"
        disabled={!stripe || !elements}
        style={{ marginTop: 30 }}
      >
        Book an Appointment
      </button>
    </form>
  );
};

const InteractiveForm = ({ data, id, handler }) => {
  const [state, setState] = useState(false);

  const handleChange = async (e) => {
    setState(e.target.checked);
    handler(id, e.target.checked);
  };

  // return
  return (
    <FormGroup>
      <FormControlLabel
        required
        control={<Switch />}
        label={data}
        onChange={handleChange}
        checked={state}
        style={{ fontSize: "xx-small", textAlign: "start" }}
      />
    </FormGroup>
  );
};

export default BookAppointmentForm;
