import { PiTelegramLogoBold } from "react-icons/pi";
import { RiHeartLine, RiHourglassLine, RiMailStarLine } from "react-icons/ri";

export const services = [
  {
    name: "NHS Services",
    description:
      "ZipLaneRx, through its extensive practitioner network, brings 20+ NHS services, paid by NHS for our customers. ",
    icon: null,
    image: true,
    imagePath: "assets/images/NHS.png",
  },
  {
    name: "Private Services",
    description:
      "ZipLaneRx works with its partner network of practitioners, to bring 150+ private paid services.",
    icon: "uil uil-pound",
  },
  {
    name: "Health and Advise",
    description:
      "Our customers can avail NHS provided minor ailment and new medicine services",
    icon: "uil uil-heart-medical",
  },
  {
    name: "Contraception Services",
    description: "ZipLaneRx brings Emergency and NHS Contraception services.",
    icon: "uil uil-book-medical",
  },
  {
    name: "Healthy Living Services",
    description:
      "Health check, stop smoking and weight management services for eligible NHS patients, also available as a private service.",
    icon: "ri-run-line",
  },
  {
    name: "Screening and Test Services",
    description:
      "Covid-19, blood pressure and pregnancy testing services for eligible NHS patients, also available as a private service.",
    icon: "ri-user-search-line",
  },
  {
    name: "Dispensing services (Coming Soon)",
    description:
      "Prescription and repeat prescription services are available for in-store pick up or home delivery.",
    icon: "uil uil-tablets",
  },
  {
    name: "Vaccination Services",
    description:
      "ZipLaneRx offers a diverse set of private and NHS paid vaccination services like seasonal flu, travel clinic and more. ",
    icon: "ri-syringe-line",
  },
];

export const servicesInteractiveForm = {
  serv00011: {
    serviceId: "serv00011",
    serviceName: "Sinusitis",
    nhsConsentLink: "https://www.nhs.uk/conditions/sinusitis-sinus-infection/",
    questionnaire: [
      {
        question: "Symptoms for ≤10 days?",
      },
      {
        question: "Symptoms for >10 days?",
      },
    ],
  },
  serv00012: {
    serviceId: "serv00012",
    serviceName: "Sore Throat",
    nhsConsentLink: "https://www.nhs.uk/conditions/sore-throat/",
    questionnaire: [
      {
        question:
          "Does the patient have signs or symptoms indicating possible scarlet fever, quinsy or glandular fever? (refer to NICE CKS for list of symptoms)",
      },
      {
        question:
          "Does the patient have signs and symptoms of suspected cancer?",
      },
      {
        question: "Is the patient immunosuppressed?",
      },
    ],
  },
  serv00006: {
    serviceId: "serv00006",
    serviceName: "Acute Otitis Media",
    nhsConsentLink: "https://www.nhs.uk/conditions/ear-infections/",
    questionnaire: [
      {
        question: "Is the patient between the age of 1 and 17 years?",
      },
      {
        question: "Is the patient systemically very unwell?",
      },
      {
        question: "Has there been signs of a more serious illness?",
      },
      {
        question:
          "Is there a pre-existing comorbidity (this includes children with significant heart, lung, renal, liver or neuromuscular disease, immunosuppression, cystic fibrosis and young children who were born prematurely)?",
      },
    ],
  },
  serv00008: {
    serviceId: "serv00008",
    serviceName: "Infected Insect Bite",
    nhsConsentLink: "https://www.nhs.uk/conditions/insect-bites-and-stings/",
    questionnaire: [
      {
        question: "Bite or scratch caused by animal(s)",
      },
      {
        question: "Bite caused by human(s)",
      },
      {
        question:
          "Bite caused by tick in the UK and signs of Lyme disease such as erythema migrans (bullseye) rash",
      },
      {
        question:
          "Bite or sting that occurred while traveling outside of UK with concern of insect-borne diseases e.g. malaria, tick-borne encephalitis",
      },
      {
        question: "Bite or sting caused by an unusual or exotic insect",
      },
      {
        question: "Redness of skin",
      },
      {
        question: "Pain or tenderness to the area",
      },
      {
        question: "Swelling of skin",
      },
      {
        question: "Skin surrounding the bite feels hot to touch",
      },
    ],
  },
  serv00007: {
    serviceId: "serv00007",
    serviceName: "Impetigo",
    nhsConsentLink: "https://www.bad.org.uk/pils/impetigo/",
    questionnaire: [
      {
        question: "Do you have ≤3 lesions/clusters present?",
      },
      {
        question: "Do you have >4 lesions/clusters present?",
      },
    ],
  },
  serv00010: {
    serviceId: "serv00010",
    serviceName: "Shingles",
    nhsConsentLink: "https://www.bad.org.uk/pils/shingles-herpes-zoster/",
    questionnaire: [
      {
        question: "Immunosuppressed (see below)",
      },
      {
        question:
          "Non-truncal involvement (shingles affecting the neck, limbs, or perineum)",
      },
      {
        question: "Moderate or severe pain",
      },
      {
        question: "Moderate or severe rash (defined as confluent lesions)",
      },
      {
        question: "All patients aged over 50 years",
      },
      {
        question: "Immunosuppressed (see below)",
      },
      {
        question: "Continued vesicle formation",
      },
      {
        question: "Severe pain",
      },
      {
        question:
          "High risk of severe shingles (e.g. severe atopic dermatitis/eczema)",
      },
      {
        question: "All patients aged 70 years and over",
      },
    ],
  },
  serv00013: {
    serviceId: "serv00013",
    serviceName: "Uncomplicated Urinary Tract Infections In Women",
    nhsConsentLink:
      "https://www.nhs.uk/conditions/urinary-tract-infections-utis/",
    questionnaire: [
      {
        question: "Kidney pain/tenderness in back under ribs",
      },
      {
        question: "New/different myalgia, flu like illness",
      },
      {
        question: "Shaking chills (rigors) or temperature 37.9°C or above",
      },
      {
        question: "Nausea/vomiting",
      },
      {
        question: "Vaginal discharge:",
      },
      {
        question: "Urethritis: inflammation post sexual intercourse, irritants",
      },
      {
        question: "Missed or lighter periods",
      },
      {
        question: "Urethritis: inflammation post sexual intercourse, irritants",
      },
      {
        question: "Do you have sexually transmitted infections?",
      },
      {
        question: "Genitourinary syndrome of menopause (vulvovaginal atrophy)",
      },
      {
        question: "Are you immunosuppressed?",
      },
    ],
  },
};

export const medicalServices = [
  {
    icon: "RiEyeFill",
    title: "Eye Care",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiPsychotherapyFill",
    title: "Psychotherapy",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiStethoscopeFill",
    title: "Primary Care",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiCapsuleFill",
    title: "Dental Care",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiMicroscopeFill",
    title: "Orthopedic",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiPulseFill",
    title: "Cardiology",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiEmpathizeFill",
    title: "Gynecology",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiMindMap",
    title: "Neurology",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiMentalHealthLine",
    title: "Dermatologists",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiAppleLine",
    title: "Nutritionists",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiInfraredThermometerLine",
    title: "Physical Therapists",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
];

export const adminFeature = [
  {
    icon: PiTelegramLogoBold,
    title: "New Messages",
    desc: "Due to its wide spread use as filler text",
  },
  {
    icon: RiMailStarLine,
    title: "Latest Proposals",
    desc: "Due to its wide spread use as filler text",
  },
  {
    icon: RiHourglassLine,
    title: "Package Expiry",
    desc: "Due to its wide spread use as filler text",
  },
  {
    icon: RiHeartLine,
    title: "Saved Items",
    desc: "Due to its wide spread use as filler text",
  },
];

export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Aland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const productCategoriesData = {
  all: { image: "assets/images/healthyliving/pills.jpg", name: "All" },
  appliances: {
    image: "assets/images/prod_categories/appliances.jpg",
    name: "Appliances",
  },
  cosmetics: {
    image: "assets/images/prod_categories/cosmetics.jpg",
    name: "Cosmetics",
  },
  dressings: {
    image: "assets/images/prod_categories/dressings.jpg",
    name: "Dressings",
  },
  "electrical-health-diagnostics": {
    image: "assets/images/prod_categories/electric.jpg",
    name: "Electrical Health & Diagnostic",
  },
  medical: { image: "assets/images/healthyliving/pills.jpg", name: "Medical" },
  otc: { image: "assets/images/healthyliving/pills.jpg", name: "OTC" },
  "pregnancy-and-mothercare": {
    image: "assets/images/prod_categories/pregnancy.jpg",
    name: "Pregnancy & Mothercare",
  },
  toiletries: {
    image: "assets/images/prod_categories/toiletries.jpg",
    name: "Toiletries",
  },
  "vitamins-and-supplements": {
    image: "assets/images/healthyliving/pills.jpg",
    name: "Vitamins & Supplements",
  },
};

export const orderDeliveryMethods = [
  {
    value: "free",
    name: "Pick Up",
    desc: "£0.00 / Pick Up at Mango Pharmacy",
    amount: 0,
  },
  // {
  //   value: "standard",
  //   name: "Standard Delivery",
  //   desc: "£7.99 / Delivery in 3 to 5 business Days",
  //   amount: 7.99,
  // },
  // {
  //   value: "express",
  //   name: "Express Delivery",
  //   desc: "£9.99 / Delivery in 1 business Days",
  //   amount: 9.99,
  // },
];

export const servicesSuitabilityForm = {
  serv00054: {
    serviceId: "serv00054",
    serviceName: "PHARMACOGENOMICS (PGx) Precision Medicine Services",
    suitable: [
      { question: "Are you taking 5 or more medications?" },
      {
        question: "Are you unhappy with your pain or mental health medication?",
      },
      {
        question:
          "Are you experiencing side effects or have had serious drug reactions?",
      },
      {
        question:
          "Are you frustrated with the trial-and-error prescribing approach?",
      },
      {
        question:
          "Do you want to potentially avoid medicine-related problems in the future?",
      },
    ],
    unsuitable: [
      {
        question:
          "Have you had a history of blood cancer affecting white blood cells (e.g., leukemia)?",
      },
      {
        question:
          "Have you undergone a whole blood transfusion within the last 20 days?",
      },
      {
        question:
          "Do you have a past medical history of bone marrow transplant?",
      },
      {
        question: "Do you have a past medical history of stem cell transplant?",
      },
    ],
  },
  serv00229: {
    serviceId: "serv00229",
    serviceName: "NUTRIGENOMICS (NGx) PRECISION MEDICINE SERVICES",
    suitable: [
      {
        question:
          "Do you have a family history of certain health conditions like metabolic disorders?",
      },
      { question: "Are you struggling with chronic health issues?" },
      {
        question:
          "Are you interested in preventive health and personalized dietary recommendations based on your genetic makeup?",
      },
      {
        question:
          "Do you experience unexplained food sensitivities or intolerances?",
      },
      {
        question:
          "Are you struggling to maintain optimal cholesterol, blood sugar, or other metabolic markers despite lifestyle changes?",
      },
    ],
    unsuitable: [
      {
        question:
          "Have you had a history of blood cancer affecting white blood cells (e.g., leukemia)?",
      },
      {
        question:
          "Have you undergone a whole blood transfusion within the last 20 days?",
      },
      {
        question:
          "Do you have a past medical history of bone marrow transplant?",
      },
      {
        question: "Do you have a past medical history of stem cell transplant?",
      },
    ],
  },
};
