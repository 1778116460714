import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";

import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import {
  getActiveServices,
  searchPharmacies,
} from "../../utils/pctmgmt.services";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { Rating } from "@mui/material";
import {
  getImage,
  mongoSearchPharmacies,
} from "../../utils/referencedata.services";
import { getNHSReviews } from "../../utils/proxy.services";
import { IoLocationSharp } from "react-icons/io5";

export default function Pharmacy() {
  // Variables
  let params = useParams();
  let entity_webstore_url = params.entity_webstore_url;
  const [pharmacy, setPharmacy] = useState(null);
  const [bannerImageId, setBannerImageId] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [hours, setHours] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [nhsMetadata, setNHSMetadata] = useState(null);
  const [activeServices, setActiveServices] = useState(null);
  const [filteredServices, setFilteredServices] = useState(null);
  const [privateServicesGrouped, setPrivateServicesGrouped] = useState({});
  const [privateServicesCategory, setPrivateServicesCategory] = useState(null);
  const [servicesActiveIndex, setServicesActiveIndex] = useState(1);

  // Init: Get Pharmacy
  useEffect(() => {
    // Practitioner Query
    let payload = { entity_webstore_url: `/${entity_webstore_url}` };

    // Make Call
    searchPharmacies(payload).then((r) => {
      let res = r.data;
      // Get First Object
      if (res.length > 0) {
        setPharmacy(res[0]);
        setBannerImageId(res[0].image_url);
        getImage(res[0].image_url)
          .then((res) => {
            setBannerImage(`data:image/;base64,${res.data.image}`);
          })
          .catch((err) => {});
      }
    });
  }, [entity_webstore_url]);

  // Set Operating Hours
  useEffect(() => {
    // Get Data
    let business_days = pharmacy?.business_days_json;
    let start_hours = pharmacy?.business_hours_start_tm_json;
    let end_hours = pharmacy?.business_hours_close_tm_json;
    let h = {};

    // Set Hours
    if (business_days) {
      Object.keys(business_days).forEach((day) => {
        h[day] = business_days[day]
          ? `${start_hours[day]} - ${end_hours[day]}`
          : "Closed";
      });
      setHours(h);
    }
  }, [pharmacy]);

  // Get Google Reviews
  useEffect(() => {
    // Check if Pharmacy
    if (pharmacy) {
      // Payload
      let payload = {
        organization_code: pharmacy?.business_ods_nbr,
      };

      // Make Call
      mongoSearchPharmacies(payload).then((r) => {
        if (r.data) {
          setMetadata(r.data);
        }
      });
    }
  }, [pharmacy]);

  // Get NHS Reviews
  useEffect(() => {
    // Check if Pharmacy
    if (pharmacy) {
      // Payload
      getNHSReviews(pharmacy?.business_ods_nbr).then((r) => {
        if (r.data) {
          setNHSMetadata(r.data);
        }
      });
    }
  }, [pharmacy]);

  // Get Active Services
  useEffect(() => {
    // Check if Pharmacy
    if (pharmacy) {
      // Payload
      getActiveServices(pharmacy?.p_nbr).then((r) => {
        if (r.data) {
          setActiveServices(r.data);
        }
      });
    }
  }, [pharmacy]);

  // Filter Services
  useEffect(() => {
    // Check if Pharmacy
    if (pharmacy && activeServices) {
      // Filter
      let filtered = activeServices.filter(
        (e) => e.nhs_in === servicesActiveIndex,
      );
      setFilteredServices(filtered);
    }
  }, [pharmacy, activeServices, servicesActiveIndex]);

  // Group Private Services
  useEffect(() => {
    // Check if Pharmacy
    if (pharmacy && activeServices && servicesActiveIndex === 0) {
      // Filter
      let filtered = filteredServices;

      // Group
      let grouped = filtered.reduce((acc, current) => {
        const key = current.srvc_type;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(current);
        return acc;
      }, {});

      console.log(grouped);
      setPrivateServicesGrouped(grouped);
    }
  }, [pharmacy, activeServices, servicesActiveIndex, filteredServices]);

  // Handlers
  const handleServiceBook = (service) => {
    window.open(
      `${process.env.REACT_APP_frontoffice_base}/book/service?webstore_referred=true&p_nbr=${pharmacy.p_nbr}&srvc_id=${service.srvc_id}`,
    );
  };

  let settings1 = {
    container: ".client-review-slider",
    items: 1,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 16,
  };

  return (
    <Fragment>
      <Header />
      {/*Background Image*/}
      <section className="home-slider position-relative">
        <div
          className="bg-half-150 d-table align-items-center w-100"
          style={{
            backgroundImage: bannerImage
              ? `url(${bannerImage})`
              : 'url("assets/images/logo-icon.png")',
            backgroundPosition: "center",
            // height: "500pt",
          }}
        >
          {/*<div className="bg-overlay bg-overlay-dark"></div>*/}
          {/*<div className="container h-100">*/}
          {/*  <div className="row h-100">*/}
          {/*    <div className="col-lg-12 d-flex justify-content-center align-items-end text-center">*/}
          {/*      <div className="heading-title text-center">*/}
          {/*        /!*<p className="text-white-50">ZipLaneRx</p>*!/*/}
          {/*        <h1 className="fw-bold text-white title-dark mb-4">*/}
          {/*          {pharmacy ? pharmacy.business_nm : ""}*/}
          {/*        </h1>*/}
          {/*        <div className="mt-5 pt-2">*/}
          {/*          <HashLink to="#about" className="btn btn-primary">*/}
          {/*            Learn More*/}
          {/*          </HashLink>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-12 d-flex justify-content-center align-items-end text-center">
              <div className="heading-title text-center">
                {/*<p className="text-white-50">ZipLaneRx</p>*/}
                <h1 className="fw-bold text-primary title-dark mb-0">
                  {pharmacy ? pharmacy.business_nm : ""}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <nav
          aria-label="breadcrumb"
          className="d-inline-block text-wrap mt-1 mb-0 pb-0"
        >
          <ul className="breadcrumb bg-light rounded mb-0 py-2 px-2">
            <li className="breadcrumb-item text-primary">
              <IoLocationSharp />
            </li>
            <li
              className="breadcrumb-item"
              aria-current="page"
              style={{ fontSize: "small" }}
            >
              {pharmacy ? pharmacy.p_addr1.toUpperCase() : ""}
            </li>
            <li
              className="breadcrumb-item"
              aria-current="page"
              style={{ fontSize: "small" }}
            >
              {pharmacy ? pharmacy.p_city.toUpperCase() : ""}
            </li>
            <li
              className="breadcrumb-item"
              aria-current="page"
              style={{ fontSize: "small" }}
            >
              {pharmacy ? pharmacy.p_zip.toUpperCase() : ""}
            </li>
            <li
              className="breadcrumb-item"
              aria-current="page"
              style={{ fontSize: "small" }}
            >
              <FaPhoneAlt />
              {pharmacy ? ` ${pharmacy.business_phone_nbr}` : ""}
            </li>
            <li
              className="breadcrumb-item"
              aria-current="page"
              style={{ fontSize: "small" }}
            >
              <MdOutlineMail />
              {pharmacy ? ` ${pharmacy.business_email_addr.toUpperCase()}` : ""}
            </li>
          </ul>
        </nav>
      </section>
      {/*About Us, Services, Hours*/}
      <div className="container mt-4 mb-4" id="about">
        <div className="row">
          {/*About Us*/}
          <div
            className="col-lg-3 col-sm-12"
            style={{
              backgroundColor: "#f0f0f0",
              boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)" /* Adds depth */,
            }}
          >
            {/*About Us Title*/}
            <div className="section-title mb-4 pb-2">
              <h2 className="title mb-4">About Us</h2>
              <p className="text-muted text-lg-start text-sm-center mb-0">
                {pharmacy ? pharmacy.business_about_us : ""}
              </p>
            </div>
            {/*Reviews*/}
            <div className="row">
              <div className="col-6">
                <div className="section-title mb-4 pb-2">
                  <h6 className="mb-2">Google Reviews</h6>
                  <div className="d-flex justify-content-center align-items-center mt-0 mb-lg-0">
                    <Rating
                      name="read-only"
                      value={metadata?.ratings || 0}
                      precision={0.5}
                      readOnly
                    />
                  </div>
                  <p>
                    <span className="fw-bold">{metadata?.ratings}</span> average
                    based on{" "}
                    <span className="fw-bold">{metadata?.totalRatings}</span>{" "}
                    Ratings
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="section-title mb-4 pb-2">
                  <h6 className="mb-2">NHS Reviews</h6>
                  <div className="d-flex justify-content-center align-items-center mt-0 mb-lg-0">
                    <Rating
                      name="read-only"
                      value={nhsMetadata}
                      precision={0.5}
                      readOnly
                    />
                  </div>
                  <Link
                    to={`${pharmacy?.business_website}/ratings-and-reviews`}
                    target="_blank"
                  >
                    <span className="fw-bold">Read all NHS Reviews</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/*Services*/}
          <div
            className="col-lg-7 col-sm-12"
            style={{
              backgroundColor: "#f0f0f0",
              boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)" /* Adds depth */,
            }}
          >
            <div className="container mt-0 mb-0" id="services">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="section-title text-start mb-0 pb-2">
                    <h4 className="title mb-4 text-center">Our Services</h4>
                    <p className="text-muted mx-auto para-desc mb-0">
                      We pride on making your service appointment seamless,
                      secure, and reliable!
                    </p>
                  </div>
                </div>
              </div>
              <div className="row my-0 py-0">
                <div className="col-12 mt-0 pt-0">
                  <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded-0 shadow overflow-hidden bg-light mt-3 mb-0">
                    <li className="nav-item">
                      <Link
                        className={`${
                          servicesActiveIndex === 1 ? "active" : ""
                        } nav-link rounded-0`}
                        to="#"
                        onClick={() => setServicesActiveIndex(1)}
                      >
                        <div className="text-center pt-1 pb-1">
                          <h6 className="title fw-bolder mb-0">NHS Funded</h6>
                        </div>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className={`${
                          servicesActiveIndex === 0 ? "active" : ""
                        } nav-link rounded-0`}
                        to="#"
                        onClick={() => setServicesActiveIndex(0)}
                      >
                        <div className="text-center pt-1 pb-1">
                          <h6 className="title fw-bolder mb-0">Private</h6>
                        </div>
                      </Link>
                    </li>
                  </ul>
                  <div
                    className="col-12"
                    style={{
                      height: "250pt",
                      boxSizing: "border-box",
                      margin: "0",
                      overflowY: "auto",
                      contentVisibility: "auto",
                      overflowMargin: "content-box",
                    }}
                  >
                    <div className="tab-pane fade show active shadow rounded py-4 px-0">
                      <div className="table-responsive bg-white shadow rounded mb-0">
                        {servicesActiveIndex === 1 ? (
                          <table
                            className="table mb-0 table-center table-wrap"
                            style={{ fontSize: "small" }}
                          >
                            <thead className="text-start">
                              <tr>
                                <th className="border-bottom p-3" scope="col">
                                  Service
                                </th>
                                {servicesActiveIndex === 0 ? (
                                  <th className="border-bottom p-3" scope="col">
                                    Service Cost
                                  </th>
                                ) : null}
                                <th className="border-bottom p-3" scope="col">
                                  Book
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text-start">
                              {filteredServices?.map((item, index) => {
                                return (
                                  <tr>
                                    <td className="p-3">
                                      {item.srvc_nm.toUpperCase()}
                                    </td>
                                    {servicesActiveIndex === 0 ? (
                                      <td className="p-3">{`£ ${item.srvc_price_amt}`}</td>
                                    ) : null}
                                    <td className="p-3">
                                      <Link
                                        to=""
                                        className="text-primary"
                                        onClick={() => {
                                          handleServiceBook(item);
                                        }}
                                      >
                                        Book{" "}
                                        <i className="uil uil-arrow-right"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : null}
                        {servicesActiveIndex === 0 ? (
                          <div className="accordion">
                            {Object.keys(privateServicesGrouped).map(
                              (item, index) => {
                                return (
                                  <div
                                    className="accordion-item border rounded mt-2 mx-2"
                                    key={index}
                                  >
                                    <h2
                                      className="accordion-header"
                                      id="headingTwo"
                                    >
                                      <button
                                        className={`${
                                          privateServicesCategory === item
                                            ? ""
                                            : "collapsed"
                                        } accordion-button border-0 bg-light px-2`}
                                        type="button"
                                        onClick={() => {
                                          privateServicesCategory !== item
                                            ? setPrivateServicesCategory(item)
                                            : setPrivateServicesCategory(null);
                                        }}
                                      >
                                        {item !== "null" ? item : "Other"}
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseTwo"
                                      className={`${
                                        privateServicesCategory === item
                                          ? "show"
                                          : ""
                                      } accordion-collapse border-0 collapse`}
                                    >
                                      <div className="accordion-body text-muted">
                                        <table
                                          className="table mb-0 table-center table-wrap"
                                          style={{ fontSize: "small" }}
                                        >
                                          <thead className="text-start">
                                            <tr>
                                              <th
                                                className="border-bottom p-3"
                                                scope="col"
                                              >
                                                Service
                                              </th>
                                              {servicesActiveIndex === 0 ? (
                                                <th
                                                  className="border-bottom p-3"
                                                  scope="col"
                                                >
                                                  Service Cost
                                                </th>
                                              ) : null}
                                              <th
                                                className="border-bottom p-3"
                                                scope="col"
                                              >
                                                Book
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody className="text-start">
                                            {privateServicesGrouped[item].map(
                                              (i, index) => {
                                                return (
                                                  <tr>
                                                    <td className="p-3">
                                                      {i.srvc_nm.toUpperCase()}
                                                    </td>
                                                    {servicesActiveIndex ===
                                                    0 ? (
                                                      <td className="p-3">{`£ ${i.srvc_price_amt}`}</td>
                                                    ) : null}
                                                    <td className="p-3">
                                                      <Link
                                                        to=""
                                                        className="text-primary"
                                                        onClick={() => {
                                                          handleServiceBook(i);
                                                        }}
                                                      >
                                                        Book{" "}
                                                        <i className="uil uil-arrow-right"></i>
                                                      </Link>
                                                    </td>
                                                  </tr>
                                                );
                                              },
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                );
                              },
                            )}
                          </div>
                        ) : // <table
                        //   className="table mb-0 table-center table-wrap"
                        //   style={{ fontSize: "small" }}
                        // >
                        //   <thead className="text-start">
                        //     <tr>
                        //       <th className="border-bottom p-3" scope="col">
                        //         Service
                        //       </th>
                        //       {servicesActiveIndex === 2 ? (
                        //         <th className="border-bottom p-3" scope="col">
                        //           Service Cost
                        //         </th>
                        //       ) : null}
                        //       <th className="border-bottom p-3" scope="col">
                        //         Book
                        //       </th>
                        //     </tr>
                        //   </thead>
                        //   <tbody className="text-start">
                        //     {filteredServices?.map((item, index) => {
                        //       return (
                        //         <tr>
                        //           <td className="p-3">
                        //             {item.srvc_nm.toUpperCase()}
                        //           </td>
                        //           {servicesActiveIndex === 2 ? (
                        //             <td className="p-3">{`£ ${item.srvc_price_amt}`}</td>
                        //           ) : null}
                        //           <td className="p-3">
                        //             <Link
                        //               to=""
                        //               className="text-primary"
                        //               onClick={() => {
                        //                 handleServiceBook(item);
                        //               }}
                        //             >
                        //               Book{" "}
                        //               <i className="uil uil-arrow-right"></i>
                        //             </Link>
                        //           </td>
                        //         </tr>
                        //       );
                        //     })}
                        //   </tbody>
                        // </table>
                        null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Hours*/}
          <div
            className="col-lg-2 col-sm-12"
            style={{
              backgroundColor: "#f0f0f0",
              boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)" /* Adds depth */,
            }}
          >
            <div className="section-title mb-0 pb-2">
              <h2 className="title mb-4">Our Hours</h2>
              <div className="flex flex-col my-2 text-lg-start text-sm-center">
                <div className="my-2">
                  <div className="fw-bold">Monday</div>
                  <div className="text-secondary text-md">{hours?.Monday}</div>
                </div>
                <div className="my-2">
                  <div className="fw-bold">Tuesday</div>
                  <div className="text-secondary text-md">{hours?.Tuesday}</div>
                </div>
                <div className="my-2">
                  <div className="fw-bold">Wednesday</div>
                  <div className="text-secondary text-md">
                    {hours?.Wednesday}
                  </div>
                </div>
                <div className="my-2">
                  <div className="fw-bold">Thursday</div>
                  <div className="text-secondary text-md">
                    {hours?.Thursday}
                  </div>
                </div>
                <div className="my-2">
                  <div className="fw-bold">Friday</div>
                  <div className="text-secondary text-md">{hours?.Friday}</div>
                </div>
                <div className="my-2">
                  <div className="fw-bold">Saturday</div>
                  <div className="text-secondary text-md">
                    {hours?.Saturday}
                  </div>
                </div>
                <div className="my-2">
                  <div className="fw-bold">Sunday</div>
                  <div className="text-secondary text-md">{hours?.Sunday}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Customer Reviews*/}
      <div className="container mt-4 mb-4">
        <div className="row justify-content-center">
          <div className="col-lg-10 text-center">
            <div className="section-title text-center mb-4 pb-2">
              <h4 className="title mb-4">
                See what our customers have to say about us
              </h4>
            </div>
            <div className="client-review-slider">
              {metadata && metadata.reviews ? (
                <TinySlider settings={settings1}>
                  {metadata?.reviews.map((item, index) => {
                    return (
                      <div className="tiny-slide text-center" key={index}>
                        <p className="text-muted fw-normal fst-italic">
                          {item.text}
                        </p>
                        <img
                          src={item.profile_photo_url}
                          className="img-fluid avatar avatar-small rounded-circle mx-auto shadow my-3"
                          alt=""
                        />
                        <div style={{ fontSize: "small" }}>
                          <Rating
                            name="read-only"
                            value={item.rating || 0}
                            precision={0.5}
                            readOnly
                          />
                        </div>
                        <h6 className="text-primary">{item.author_name} </h6>
                        <div style={{ fontSize: "small" }}>
                          <h6 className="text-primary">
                            <small className="text-muted">
                              {item.relative_time_description}
                            </small>
                          </h6>
                        </div>
                      </div>
                    );
                  })}
                </TinySlider>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
